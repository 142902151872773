<template>
    <v-container style="max-width:100vw;" v-if="this.currentUser.permissions!=undefined">
        <totals v-if="permissions('salesTotals')" v-bind:totalsFilters="{'selectedStatus':selectedStatus,'prop_brand_id':prop_brand_id,'prop_agency_id':prop_agency_id}"/>
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterQuotations v-bind:company="[prop_brand_id, prop_agency_id]" @filtersQuotation="filtersQuotation"/>
        </v-navigation-drawer>
        <!-- Contenedor show-expand -->
        <v-data-table 
        :item-class="itemRowBackground" 
        :headers="showHeaders" 
        :items="quotations" 
        class="elevation-0 px-6 py-4"
        group-by="month" 
        height="600"
        fixed-header
        :footer-props="{'items-per-page-options':[15, 30, 50, 500, totalQuotations>999?999:totalQuotations]}"
        v-if="showTable"
        :options.sync="options"
        v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
        :key="anIncreasingNumber" 
        :server-items-length="totalQuotations"
        :loading="loading">
            <!-- Header -->
            <template v-slot:group.header="{ items }">

                <td v-for="(h, index) in headers" :key="index">
                    <strong v-if="index<1">{{items[0].month}}</strong>
                    
                    <strong v-if="h.value=='subtotal'">{{sumItems(items).subtotal.toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                    <strong v-if="h.value=='iva'">{{sumItems(items).iva.toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                    <strong v-if="h.value=='total'">{{sumItems(items).total.toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                    
                    <strong v-if="h.value=='peach_amount'">{{sumItems(items).peach.toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                    <strong v-if="h.value=='influencer_amount'">{{sumItems(items).influencer.toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                    
                    <strong v-if="h.value=='expense_amount'">{{(sumItems(items).expense*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}</strong>
                </td>
                
            </template>
            <template v-slot:top>
                <v-toolbar flat class="mb-2">
                    <v-toolbar-title>Ventas</v-toolbar-title>

                    <v-btn icon v-if="filterStorageLength==0" @click="openFilter()">
                        <v-icon>mdi-filter</v-icon>
                    </v-btn>

                    <v-btn icon v-else @click="openFilter()">
                        <v-badge overlap color="primary">
                            <template v-slot:badge>
                                {{filterStorageLength}}
                            </template>
                            <v-icon>mdi-filter</v-icon>
                        </v-badge>
                    </v-btn>

                    <v-select style="max-width:300px!important;" class="ml-4 mt-6" v-model="selectedStatus" :items="statuses" label="Status de Venta" item-text="text" item-value="value" dense chips outlined small-chips></v-select>

                    <v-spacer></v-spacer>
                    <v-select style="max-width:350px!important;" class="mr-4 mt-6" v-model="selectedHeaders" :items="headers" label="Columnas" dense multiple outlined return-object>
                        <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                                <v-icon :color="selectedHeaders.length > 0 ? 'primary' : ''">
                                {{ icon }}
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                Seleccionar Todo
                                </v-list-item-title>
                            </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" x-small v-if="index < 2">
                                <span style="font-size:11px;">{{ item.text }}</span>
                            </v-chip>
                            <span v-if="index === 2" style="font-size:10px!important;" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} más)</span>
                        </template>
                    </v-select>
                    <v-menu offset-y v-show="permissions('downloadSales')">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportExcel(options.itemsPerPage)">
                                <v-list-item-title >Exportar <span style="font-size:10px;">({{options.itemsPerPage}})</span></v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportExcel(totalQuotations)">
                                <v-list-item-title>Exportar Todo <span style="font-size:10px;">({{totalQuotations}})</span></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                </v-toolbar>
                <v-row class="ma-0 pb-4 pt-2">
                    <v-icon color="#0080002b" class="mr-1">mdi-label</v-icon> 
                    <span style="font-size:12px; line-height:24px!important;">Ya se cobro al Cliente y se pago a Influencer</span>
                    <v-spacer/>
                    <v-icon color="#fff2ca" class="mr-1">mdi-label</v-icon> 
                    <span style="font-size:12px; line-height:24px!important;">Ya se cobro al Cliente y no se ha pagado a Influencer</span>
                    <v-spacer/>
                    <v-icon color="#d9ebff" class="mr-1">mdi-label</v-icon> 
                    <span style="font-size:12px; line-height:24px!important;">No se ha cobrado al Cliente y ya se pago a Influencer</span>
                    <v-spacer/>
                    <v-icon color="#ff75002b" class="mr-1">mdi-label</v-icon> 
                    <span style="font-size:12px; line-height:24px!important;">Tiene fecha de programacion de pago</span>
                    <v-spacer/>
                </v-row>
            </template>
            <template v-slot:[`item.retencion`]="{ item }">
                {{(item.subtotal*0.03).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <!--template v-slot:[`item.is_expired`]="{ item }">
            {{item.is_expired?'Finalizado':'Activo'}}
            </template-->
            <template v-slot:[`item.is_active`]="{ item }">
                {{item.is_active?'Activo':'Finalizado'}}
            </template>
            <template v-slot:[`item.in_credit`]="{ item }">
                <v-icon v-if="item.in_credit">mdi-check-circle</v-icon>
            </template>
            <template v-slot:[`item.needs_authorization`]="{ item }">
                <v-icon v-if="item.needs_authorization">mdi-check-circle</v-icon>
            </template>
            <template v-slot:[`item.expense_percentage`]="{ item }">
                {{item.expense_percentage}}%
            </template>
            <template v-slot:[`item.expense_amount`]="{ item }">
                {{(item.expense_amount*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.subtotal`]="{ item }">
                {{(item.subtotal*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.iva`]="{ item }">
                {{(item.iva*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.total`]="{ item }">
                {{(item.total*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.peach_amount`]="{ item }">
                {{(item.peach_amount*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.influencer_amount`]="{ item }">
                {{(item.influencer_amount*1).toLocaleString('es-MX', { style: 'currency', minimumFractionDigits: 0, currency: 'MXN',})}}
            </template>
            <template v-slot:[`item.peach_percentage`]="{ item }">
                {{item.peach_percentage}}%
            </template>
            <template v-slot:[`item.influencer_percentage`]="{ item }">
                {{item.influencer_percentage}}%
            </template>

            <template v-slot:[`item.influencer`]="{ item }">
                <a class="btnText2" :href="item.influencer!=undefined?('#/influencers/influencer/'+ item.influencer.id):''">{{item.influencer!=undefined?item.influencer.social_networks.instagram:''}}</a>
            </template>

            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left v-if="permissions('editSales')==true || permissions('deleteSales')==true">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item.editedItem)"  v-show="permissions('editSales')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item.id)"  v-show="permissions('deleteSales')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                        <v-list-item v-if="permissions('paySalesToinfluencer')" @click="influencerPayment(item.editedItem)">
                            <v-icon small class="mr-2">
                                mdi-account-cash
                            </v-icon>
                            Pagar a Influencer
                        </v-list-item>
                        <v-list-item @click="promiseDate(item.editedItem)"><!-- v-show="permissions('editSchedulingDate')"-->
                            <v-icon small class="mr-2">
                                mdi-calendar
                            </v-icon>
                            Programacion de Pago
                        </v-list-item>
                        <v-list-item @click="deadlineDate(item.editedItem)">
                            <v-icon small class="mr-2">
                                mdi-calendar
                            </v-icon>
                            Fecha Cierre
                        </v-list-item>
                        <v-list-item @click="liquidar(item.editedItem)" v-if="permissions('markSalesAsPaid')">
                            <v-icon small class="mr-2">
                                mdi-cash-check
                            </v-icon>
                            Venta Pagada
                        </v-list-item>
                        <!--v-list-item v-if="item.pay_day==null" @click="confirmStatus(item.id, 'Vencida')">
                            <v-icon small class="mr-2">
                                mmdi-credit-card-clock-outline
                            </v-icon>
                            Venta Vencida
                        </v-list-item-->
                        <v-list-item v-if="item.pay_day==null" @click="confirmStatus(item.id, item.is_active?'Finalizada':'Activa')">
                            <v-icon small class="mr-2">
                                mdi-credit-card-clock-outline
                            </v-icon>
                            {{item.is_active?'Finalizar ':'Activar '}} Venta
                        </v-list-item>
                        <v-list-item v-show="permissions('cancelSales')" @click="cancellItem(item.id)">
                            <v-icon small class="mr-2">
                                mdi-close
                            </v-icon>
                            Cancelar
                        </v-list-item>

                        <!-- Crear gasto -->
                        <v-dialog v-model="createExpenseDialog" max-width="700px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                    <v-icon small class="mr-2">
                                        mdi-currency-usd-off
                                    </v-icon>
                                    Crear Gasto
                                </v-list-item>
                            </template>
                            <createExpense v-bind:sale="item" @closeCreateDialogExpense="closeCreateDialogExpense"/>
                        </v-dialog>

                    </v-list>
                </v-menu>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>
            <!-- Actualización -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{item.updated_at.slice(0, 10)}}
            </template>
            <template v-slot:[`item.pdfs`]="{ item }">
                <div v-if="item.pdfs!=undefined" >
                    <v-btn v-for="(pdf, index) in item.pdfs" :key="index" v-bind:href="'https://unopack.mx/files/' + pdf" target="_blank" icon>
                        <v-icon small>mdi-file-check-outline</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.invoice_file`]="{ item }">
                <v-btn v-if="item.invoice_file!=undefined" v-bind:href="'https://unopack.mx/files/' + item.invoice_file" target="_blank" icon>
                    <v-icon small>mdi-file-check-outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.peach_invoice_file`]="{ item }">
                <v-btn v-if="item.peach_invoice_file!=undefined" v-bind:href="'https://unopack.mx/files/' + item.peach_invoice_file" target="_blank" icon>
                    <v-icon small>mdi-file-check-outline</v-icon>
                </v-btn>
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de ventas aún
            </template>
            <!-- Empresa -->
            <template v-slot:[`item.company`]="{ item }">
                <v-list-item class="px-0" style="min-height:0px!important; font-size:14px;" :to="{ path: '/clients/client/'+ item.companyID}">{{item.company}}</v-list-item>
            </template>
            <!-- macro -->
            <template v-slot:[`item.bar`]="{ item }">
                <v-icon v-if="item.bar==true">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>
        </v-data-table>
        <!-- Crear actividad -->
        <v-dialog v-model="createDialog" max-width="900px" v-if="permissions('createSales')">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#58c6ef" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <create v-bind:message="[prop_brand_id, statusss]" @closeCreateDialogQuotation="closeCreateDialogQuotation"/>
        </v-dialog>
        <!-- Editar actividad -->
        <v-dialog v-model="editDialog" max-width="900px">
          <edit v-bind:editedQuotation="quotation" @closeDialogEditQuotation="closeDialogEditQuotation"/>
        </v-dialog>
        <!-- Enviar email -->
        <v-dialog v-model="emailDialog" max-width="700px">
          <email v-bind:quotation="quotation" @closeDialogEmailQuotation="closeDialogEmailQuotation"/>
        </v-dialog>
        <!-- Dialogo confirmación de eliminación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro(a) que deseas borrar esta venta?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteQuotation()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-- Dialogo confirmación de estatus -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet2" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas cambiar el estatus de esta cotización a {{statusData}}?
                    </div>
                    <v-btn class="mt-4" text color="primary" @click="status()">
                    Si
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    No
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <!-- Motivo de Rechazo -->
        <v-dialog v-model="rejectionDialog" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Motivo de Rechazo: 
                <v-autocomplete clearable v-model="rejectionId" :items="rejectionsLists" label="Motivo de Cancelación" item-text="name" item-value="id">
                    <template slot="no-data" class="pa-2">No existen motivos relacionados.</template>                      
                </v-autocomplete>
                <v-textarea v-model="rejectionData" label="Descripcion"></v-textarea>
                <v-btn color="blue darken-1" text @click="rejectionDialog=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="confirmStatus(statusId, 'cancelado')">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>
        <!-- Pago Influencer -->
        <v-dialog v-model="influencerPaymentDialg" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Pago Influencer: 
                <v-menu v-model="datePicker1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="mt-4 mb-2" :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.influencer_payment_date" label="Fecha de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.influencer_payment_date" @input="datePicker1 = false"></v-date-picker>
                </v-menu>
                <v-text-field  v-model="quotation.bank_account" label="Cuenta Banco"></v-text-field>                
                <div class="pb-4" style="color:grey; font-size:13px;" v-if="quotation.influencer!=undefined">{{quotation.influencer.bank_account_details}}</div>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="influencerPaymentDialg=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveInfluencerPayment()">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>
        <!-- Fecha Promesa -->
        <v-dialog v-model="promiseDateDialog" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Fecha Programacion de Pago: 
                <v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="pb-6 mt-4" :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.payment_scheduling_date" label="Fecha" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.payment_scheduling_date" @input="datePicker2 = false"></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="promiseDateDialog=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="savePromiseDate()">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>
        <!-- Fecha Corte -->
        <v-dialog v-model="deadlineDateDialog" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Fecha Cierre: 
                <v-menu v-model="datePicker2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="pb-6 mt-4" :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.deadline_date" label="Fecha" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.deadline_date" @input="datePicker2 = false"></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deadlineDateDialog=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveDeadlineDate()">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>
        
        <!-- Venta Pagada -->
        <v-dialog v-model="liquidarDialog" max-width="400px">
            <div class="px-12 py-6" style="background-color:white;">
                Venta Pagada: 
                <v-menu v-model="datePicker3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="pb-6 mt-4" :rules="[v => !!v || 'Campo requerido']" clearable required v-model="quotation.pay_day" label="Fecha de Pago" prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="quotation.pay_day" @input="datePicker3 = false"></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="promiseDateDialog=false">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveLiquidacion()">
                    Guardar
                </v-btn>
            </div>
        </v-dialog>

        
    </v-container>
</template>

<script>
    function watchClass(targetNode, classToWatch) {
        let lastClassState = targetNode.classList.contains(classToWatch);
        const observer = new MutationObserver((mutationsList) => {
            for (let i = 0; i < mutationsList.length; i++) {
            const mutation = mutationsList[i];
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const currentClassState = mutation.target.classList.contains(classToWatch);
                if (lastClassState !== currentClassState) {
                lastClassState = currentClassState;
                if (!currentClassState) {
                    mutation.target.classList.add('sortHandle');
                }
                }
            }
            }
        });
        observer.observe(targetNode, { attributes: true });
    }
    import Sortable from 'sortablejs';
    import axios from "axios";
    import XLSX from 'xlsx';
    import Filter from "../sales/filter"
    import Create from "../quotations/create"
    import Edit from "../quotations/edit"
    import Email from "../quotations/sendQuotation"
    import Totals from "../sales/totals"
    import CreateExpense from "../expenses/create"
    export default {
        props:{
            prop_brand_id:Number,
            prop_agency_id:Number,
            influencer:String
        },
        components: {
            'filterQuotations':Filter,
            'create':Create,
            'edit':Edit,
            'email':Email,
            'totals':Totals,
            'createExpense':CreateExpense
        }, 
        data: () => ({
            anIncreasingNumber: 1,
            totalType:'sum',
            sumavg:['sum', 'avg'],
            campo:'total',
            campos:[
                { text: 'Subtotal', value: 'subtotal' },
                { text: 'IVA', value: 'iva' },
                { text: 'Total', value: 'total' },
                { text: 'Ganancia Peach', value: 'amount_peach' },
                { text: 'Ganancia Influencer(s)', value: 'influencer_amount' },
            ],
            chip1:true,
            datePicker1:false,
            datePicker2:false,
            datePicker3:false,
            influencerPaymentDialg:false,
            promiseDateDialog:false,
            deadlineDateDialog:false,
            createExpenseDialog:false,
            statusss:'vendido',
            statusData:'',
            statusId:'',
            quotation:'',
            sheet2: false,
            sheet: false,
            filters: false,
            emailDialog: false,
            editDialog: false,
            createDialog: false,
            detailDialog:false,
            rejectionDialog:false,
            liquidarDialog:false,
            deleteId:'',
            rejectionData:'',
            rejectionId:'',
            quotations: '',
            headers2:[
                { text: 'Cantidad', value: 'quantity',},
                { text: 'Servicio', value: 'item',},
                { text: 'Precio Ajustado', value: 'price',},
                { text: 'Total', value: 'total',},
                { text: 'Influencer', value: 'influencer_id',},
                { text: 'Pagado', value: 'pay',},
            ],
            headers:[],
            selectedHeaders: [],
            selectedStatus: 'pending_sales',
            options: {},
            showTable:true,
            totalQuotations:0,
            loading:false,
            filterStorageLength:0,
            quotationsLength:0,
        }),
        computed: {
            statuses(){
                var statuses = [
                    {text:'Todo', value:'all'},
                    {text:'Ventas Pendientes', value:'pending_sales'},
                    {text:'Ventas Pagadas a Influencers', value:'paid_to_influencers'},
                    {text:'Ventas Blancas', value:'white_sales'},
                    {text:'Ventas sin Fecha de Cierre', value:'deadline_date_null'},
                ]
                if(!this.permissions('hideCompletedSales')){
                    statuses.push(
                        {text:'Ventas Completadas', value:'completed_sales'}
                    )
                }
                return statuses
            },
            /*filterStorageLength(){
                return localStorage.getItem("filtersSalesLength")*1
            },*/
            showHeaders () {
                if(this.quotations!=''){
                    return this.headers.filter(s => this.selectedHeaders.includes(s));
                }else{
                    this.quotations = this.quotationsLists
                    return this.headers.filter(s => this.selectedHeaders.includes(s));
                }
            },
            showAgency(){
                if(this.prop_agency_id == null){
                    return { text: 'Agencia', value: 'agency', sortable: false}
                }else{
                    return false
                }
            },
            showBrand(){
                if(this.prop_brand_id == null){
                    return { text: 'Marca', value: 'brand', sortable: false}
                }else{
                    return false
                }
            },
            currentUser(){
                return this.$store.state.currentUser.user;
            },
            department1(){
                if(this.currentUser!=undefined && (this.currentUser.role == 'Administrador' || this.currentUser.role == 'Key Account Management' || this.currentUser.role == 'Key Account Management Manager')){
                    return { text: 'Departamento', value: 'department' }
                }else{
                    return {}
                }
            },
            headersMap(){ 
                if(this.permissions('hideSaleColums')){
                    return [
                        { text: 'Folio', value: 'id' },
                        this.department1,
                        { text: 'Influencer', value: 'influencer', sortable: false},
                        this.showAgency,
                        this.showBrand,                
                        { text: 'Campaña', value: 'campaign' },
                        { text: 'Contacto Campaña', value: 'campaign_contact', sortable: false},
                        //{ text: 'Contacto', value: 'contact' },
                        { text: 'Servicio(s)', value: 'service' },
                        { text: 'Fecha de Servicio', value: 'service_date' },
                        { text: 'Subtotal', value: 'subtotal' },
                        { text: 'IVA', value: 'iva' },
                        { text: 'Total', value: 'total' },
                        { text: '% Peach', value: 'peach_percentage' },
                        { text: '% Influencer', value: 'influencer_percentage' },
                        { text: '$ Peach', value: 'peach_amount' },
                        { text: '$ Influencer', value: 'influencer_amount' },
                        //{ text: '3%', value: 'retencion' },
                        //{ text: '% Gasto', value: 'expense_percentage' },
                        { text: '$ Gasto', value: 'expense_amount' },
                        //{ text: 'Factura Influencer', value: 'influencer_invoice' },
                        { text: 'Fecha Pago Influencer', value: 'influencer_payment_date' },
                        { text: 'Factura', value: 'invoice' },
                        { text: 'Factura Marca', value: 'peach_invoice_file' },
                        { text: 'Factura Influencer', value: 'invoice_file' },
                        { text: 'Contrato', value: 'pdfs' },
                        { text: 'Fecha Factura', value: 'invoice_date' },
                        //{ text: 'Promesa de Pago', value: 'payment_promise_date', sortable: false },
                        { text: 'Contacto Financiero', value: 'finance_contact', sortable: false},
                        { text: 'Fecha Cierre', value: 'deadline_date' },
                        { text: 'Fecha de Pago', value: 'pay_day' },
                        { text: 'Creación', value: 'created_at' },
                        { text: 'Responsable', value: 'salesman' },
                        //{ text: 'Activo/Finalizado', value: 'is_expired' },
                        { text: 'Activo/Finalizado', value: 'is_active' },
                        { text: 'En credito', value: 'in_credit' },
                        { text: 'Fuera de limite de credito', value: 'needs_authorization' },
                        { text: 'Autorizado por', value: 'authorized_by_user' },
                        { value: 'actions', sortable: false, align: 'end', },
                        { value: 'month', sortable: false, align: 'end', }
                    ]
                }else{
                    return [
                        { text: 'Folio', value: 'id' },
                        this.department1,
                        { text: 'Influencer', value: 'influencer', sortable: false},
                        this.showAgency,
                        this.showBrand,                
                        { text: 'Campaña', value: 'campaign' },
                        //{ text: 'Contacto', value: 'contact' },
                        { text: 'Servicio(s)', value: 'service' },
                        { text: 'Fecha de Servicio', value: 'service_date' },
                        { text: '% Influencer', value: 'influencer_percentage' },
                        { text: '$ Influencer', value: 'influencer_amount' },
                        //{ text: '3%', value: 'retencion' },
                        //{ text: '% Gasto', value: 'expense_percentage' },
                        { text: '$ Gasto', value: 'expense_amount' },
                        //{ text: 'Factura Influencer', value: 'influencer_invoice' },
                        { text: 'Factura', value: 'invoice' },
                        { text: 'Factura Marca', value: 'peach_invoice_file' },
                        { text: 'Factura Influencer', value: 'invoice_file' },
                        { text: 'Contrato', value: 'pdfs' },
                        //{ text: 'Promesa de Pago', value: 'payment_promise_date', sortable: false },
                        { text: 'Fecha Cierre', value: 'deadline_date' },
                        { text: 'Fecha de Pago', value: 'pay_day' },
                        { text: 'Creación', value: 'created_at' },
                        { text: 'Responsable', value: 'salesman' },
                        //{ text: 'Venta Vencida', value: 'is_expired' },
                        { text: 'Activo/Finalizado', value: 'is_active' },
                        { text: 'En credito', value: 'in_credit' },
                        { text: 'Fuera de limite de credito', value: 'needs_authorization' },
                        { text: 'Autorizado por', value: 'authorized_by_user' },
                        { value: 'actions', sortable: false, align: 'end', },
                        { value: 'month', sortable: false, align: 'end', }
                    ]
                }
            },
            rejectionsLists(){
                return this.$store.state.rejection.rejections;
            },
            quotationsList(){
                return this.$store.state.quotation.sales
            },
            likesAllFruit () {
                return this.selectedHeaders.length === this.headersMap.length
            },
            likesSomeFruit () {
                return this.selectedHeaders.length > 0 && !this.likesAllFruit
            },
            icon () {
                if (this.likesAllFruit) return 'mdi-close-box'
                if (this.likesSomeFruit) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        mounted () {
            this.headers = Object.values(this.headersMap);
            this.selectedHeaders = this.headers;
        },
        directives: {
            'sortable-table': {
                inserted: (el, binding) => {
                    el.querySelectorAll('th').forEach((draggableEl) => {
                        // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
                        watchClass(draggableEl, 'sortHandle');
                        draggableEl.classList.add('sortHandle');
                    });
                    Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
                },
            },
        },
        watch: {
            
            options: {
                handler () {
                    if(JSON.stringify(this.currentUser).length>10){
                        this.getDataFromApi()
                    }
                },
                deep: true,
            },
            selectedStatus: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
            currentUser: {
                handler () {
                    this.getDataFromApi()
                    this.headers = Object.values(this.headersMap);
                    this.selectedHeaders = this.headers;
                },
                deep: true,
            },
        },
        methods: {
            sumItems(items){
                var totals = {
                    subtotal:0,
                    iva:0,
                    total:0,
                    peach:0,
                    influencer:0,
                    expense:0
                }
                for(var i=0; i<items.length; i++){
                    totals.subtotal = (totals.subtotal*1) + (items[i].subtotal*1)
                    totals.iva = (totals.iva*1) + (items[i].iva*1)
                    totals.total = (totals.total*1) + (items[i].total*1)
                    totals.peach = (totals.peach*1) + (items[i].amount_peach*1)
                    totals.influencer = (totals.influencer*1) + (items[i].influencer_amount*1)
                    totals.expense = (totals.expense*1) + (items[i].expense_amount*1)
                }
                return totals
            },
            getDataFromApi () {
                this.loading = true
                this.apiCall().then(data => {
                    this.quotations = data.items
                    this.totalQuotations = data.total
                    this.loading = false
                })
            },
            apiCall () {
                return new Promise((resolve, reject) => {
                    this.filterStorageLength = localStorage.getItem('filtersSalesLength')
                    const { sortBy, sortDesc, page, itemsPerPage } = this.options
                    var items = []
                    var total = 0
                    var link = this.$store.state.currentUser.sale_filter
                    if(localStorage.getItem('filtersSales')!=null){
                        link = link + JSON.parse(localStorage.getItem('filtersSales'))+'&'
                    }
                    if(this.prop_brand_id!=undefined){
                        link = link + 'filter[brand_id]=' + this.prop_brand_id + '&'
                    }
                    if(this.prop_agency_id!=undefined){
                        link = link + 'filter[agency_id]=' + this.prop_agency_id + '&'
                    }
                    if(this.influencer!=undefined){
                        link = link + 'filter[influencer_id]=' + this.influencer + '&'
                    }
                    if(this.selectedStatus!='all'){
                        link = link + 'filter[' + this.selectedStatus + ']=1&'
                    }
                    if(this.$route.params.sale_id!=undefined){
                        link = link + 'filter[id]=' + this.$route.params.sale_id + '&'
                    }
                    if(sortBy.length === 1){
                        if(sortDesc[0]){
                            link = link + "sort=-" + sortBy[0] + '&'
                        }else{
                            link = link + "sort=" + sortBy[0] + '&'
                        }
                    }
                    axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales?" + link + "page=" + page + "&itemsPerPage=" + itemsPerPage + '&status=vendido').then(response => {
                        this.quotationsLength = response.data.meta.total
                        items = this.mapQuotations(response.data.data)
                        total = response.data.meta.total
                        resolve({
                            items,
                            total,
                        })
                    })
                })
            },
            agency(agency){
                if(agency!=undefined){
                    return agency.name
                }else{
                    return ''
                }
            },
            department(is_boooking){
                if(is_boooking){
                    return 'BOOKING'
                }else{
                    return 'MGMT'
                }
            },
            mapQuotations(quotations){
                quotations =  quotations.map(id=>{
                    return{
                        editedItem: id,
                        id: id.id,
                        department: id.influencer != undefined ? id.influencer.department : '',
                        booking: id.influencer != undefined ? this.department(id.influencer.is_booking) : '',
                        influencer: id.influencer,
                        agency: this.agency(id.agency),
                        brand: this.agency(id.brand),
                        campaign_contact: this.contact(id.campaign_contact),
                        finance_contact: this.contact(id.finance_contact),
                        //contact: id.contact.name,
                        service: id.service,
                        service_date: id.service_date,
                        subtotal: id.subtotal,
                        //expense_percentage: this.calcExpense(id.expense_percentage),
                        expense_amount: this.calcExpense(id.expenses),
                        iva: id.iva,
                        total: id.total,
                        percent_peach: id.peach_percentage + '%',
                        percent_influencer: id.influencer_percentage+ '%',
                        amount_peach: id.peach_amount,
                        influencer_amount: id.influencer_amount,
                        influencer_payment_date: id.influencer_payment_date,
                        invoice: id.invoice,
                        invoice_date: id.invoice_date,
                        payment_promise_date: id.payment_promise_date,
                        payment_scheduling_date:id.payment_scheduling_date,
                        pay_day: id.pay_day,
                        salesman: id.agent.name + ' ' + id.agent.last,
                        promesa_de_pago: this.cualFecha(id.deadline_date, id.created_at.slice(0, 10)),
                        campaign:id.campaign,
                        influencer_percentage:id.influencer_percentage,
                        peach_percentage:id.peach_percentage,
                        peach_amount:id.peach_amount,
                        deadline_date:id.deadline_date,
                        pdfs:id.pdfs,
                        invoice_file:id.invoice_file,
                        peach_invoice_file:id.peach_invoice_file,
                        created_at:id.created_at,
                        //is_expired:id.is_expired,
                        is_active:id.is_active,
                        authorized_by_user:this.mapUser(id.authorized_by_user)
                    }
                }).sort((a, b) => {
                    return a.promesa_de_pago - b.promesa_de_pago;
                }).map(id=>{
                    return{
                        id: id.id,
                        department: id.department,
                        booking: id.booking,
                        influencer: id.influencer,
                        agency: id.agency,
                        brand: id.brand,
                        service: id.service,
                        service_date: id.service_date,
                        subtotal: id.subtotal,
                        expense_percentage:id.expense_percentage,
                        expense_amount:id.expense_amount,
                        iva: id.iva,
                        total: id.total,
                        percent_peach: id.percent_peach,
                        percent_influencer: id.percent_influencer,
                        amount_peach: id.amount_peach,
                        influencer_amount: id.influencer_amount,
                        influencer_payment_date: id.influencer_payment_date,
                        invoice: id.invoice,
                        invoice_date: id.invoice_date,
                        payment_promise_date: id.payment_promise_date,
                        payment_scheduling_date:id.payment_scheduling_date,
                        pay_day: id.pay_day,
                        salesman: id.salesman,
                        editedItem: id.editedItem,
                        month: this.promesadepago(id.deadline_date),
                        campaign:id.campaign,
                        influencer_percentage:id.influencer_percentage,
                        peach_percentage:id.peach_percentage,
                        peach_amount:id.peach_amount,
                        pdfs:id.pdfs,
                        deadline_date:id.deadline_date,
                        invoice_file:id.invoice_file,
                        peach_invoice_file:id.peach_invoice_file,
                        created_at:id.created_at,
                        campaign_contact: id.campaign_contact,
                        finance_contact: id.finance_contact,
                        //is_expired:id.is_expired,
                        is_active:id.is_active,
                        authorized_by_user:id.authorized_by_user,
                        needs_authorization:id.needs_authorization,
                        in_credit:id.in_credit
                    }
                })
                return quotations
            },
            mapUser(user){
                if(user!=undefined){
                    return user.name + ' ' + user.last
                }
            },
            contact(contact){
                if(contact!=undefined){
                    return contact.name + ' ' + contact.last
                }
            },
            existExpense(expense){
                if(expense!=undefined){
                    return expense.percentage_over_sale*1
                }else{
                    return 0
                }
            },
            cualFecha(payment_promise_date, created_at){
                var date = ''
                if(payment_promise_date!=null){
                    date = payment_promise_date + ' ' + '00:00:00'
                }else{
                    date = created_at + ' ' + '00:00:00'
                }
                return new Date(date)
            },
            promesadepago(date){
                if(date==null){
                    return 'SIN FECHA CIERRE'
                }else{
                    return this.month(date.slice(5,7)) + ' ' + date.slice(0,4)
                }
            },
            calcExpense(expenses){
                var sum = 0
                for(var i=0; i<expenses.length; i++){
                    sum = sum + (expenses[i].amount*1)
                }
                return sum 
            },
            sortTheHeadersAndUpdateTheKey(evt) {
                const headersTmp = this.showHeaders;
                const oldIndex = evt.oldIndex;
                const newIndex = evt.newIndex;
                if (newIndex >= headersTmp.length) {
                    let k = newIndex - headersTmp.length + 1;
                    while (k--) {
                        headersTmp.push(undefined);
                    }
                }
                headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
                this.table = headersTmp;
                this.anIncreasingNumber += 1;
            },
            toggle () {
                this.$nextTick(() => {
                    if (this.likesAllFruit) {
                        this.selectedHeaders = []
                    } else {
                        this.selectedHeaders = this.headersMap.slice()
                    }
                })
            },
            month(date){
                var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
                return meses[(date*1)-1]
            },
            itemRowBackground: function (item) {
                if(item.pay_day!=null && item.influencer_payment_date!=null){
                    return item.is_active?'is_activeStyle-3':'is_inactiveStyle-3'//verde
                }
                else if(item.influencer_payment_date!=null){
                    return item.is_active?'is_activeStyle-2':'is_inactiveStyle-2'//azul
                }
                else if(item.payment_scheduling_date!=null){
                    return item.is_active?'is_activeStyle-4':'is_inactiveStyle-4'//naranja
                }
                else if(item.pay_day!=null){
                    return item.is_active?'is_activeStyle-1':'is_inactiveStyle-1'//amarillo
                }
                else{
                    return item.is_active?'is_active':'is_inactive'
                }
            },
            booking(id){
                return this.$store.state.user.users.filter(user=>user.id == id).map(influencer=>influencer.booking)[0]
            },
            influencerPayment(item){
                this.quotation = item
                this.influencerPaymentDialg = true
            },
            promiseDate(item){
                this.quotation = item
                this.promiseDateDialog = true
            },
            deadlineDate(item){
                this.quotation = item
                this.deadlineDateDialog = true
            },
            vencer(item){
                this.quotation = item
                this.dialogoVencido = true
            },
            liquidar(item){
                this.quotation = item
                this.liquidarDialog = true
            },
            saveInfluencerPayment(){
                var editedItem = {
                    id:this.quotation.id,
                    bank_account: this.quotation.bank_account,
                    influencer_payment_date: this.quotation.influencer_payment_date
                }
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + editedItem.id, editedItem).then(response=>{
                    this.influencerPaymentDialg = false
                    this.getDataFromApi()
                })
            },
            saveDeadlineDate(){
                var editedItem = {
                    id:this.quotation.id,
                    deadline_date:this.quotation.deadline_date
                }
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + editedItem.id, editedItem).then(response=>{
                    this.deadlineDateDialog = false
                    this.getDataFromApi()
                })
            },
            savePromiseDate(){
                var editedItem = {
                    id:this.quotation.id,
                    payment_scheduling_date:this.quotation.payment_scheduling_date
                }
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + editedItem.id, editedItem).then(response=>{
                    this.promiseDateDialog = false
                    this.getDataFromApi()
                })
            },
            saveLiquidacion(){
                var editedItem = {
                    id:this.quotation.id,
                    pay_day:this.quotation.pay_day
                }
                axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/mark_as_paid/" + editedItem.id, editedItem).then(response=>{
                    this.liquidarDialog = false
                    this.getDataFromApi()
                })
            },
            servicess(items){
                var newItems = []
                for(var i=0; i<items[0].items.length; i++){
                    newItems = newItems.concat(items[0].items[i].item)
                }
                return newItems
            },
            mapItems(items, id){
                var newItems = []
                if(this.influencer!=undefined && this.influencer!='' & this.influencer!=null){
                    for(var i=0; i<items.length; i++){
                        if(items[i].influencer_id==this.influencer){
                            for(var j=0; j<items[i].items.length; j++){
                                items[i].items[j].influencer_id = items[i].influencer_id
                                items[i].items[j].sale_id = id
                                newItems = newItems.concat(items[i].items[j])
                            }
                        }
                    }
                    return newItems
                }else{ 
                    for(var i=0; i<items.length; i++){
                        for(var j=0; j<items[i].items.length; j++){
                            items[i].items[j].influencer_id = items[i].influencer_id
                            items[i].items[j].sale_id = id
                            newItems = newItems.concat(items[i].items[j])
                        }
                    }
                    return newItems
                }
            },
            influencerId(items){
                for(var i=0; i<items.length; i++){
                    if(items[i].influencer_id == this.influencer){
                        return true
                    }
                }
            },
            openFilter(){
                if(this.filters == false){
                    this.$emit("closeDrawer", false);
                    this.filters = true
                }else{
                    this.$emit("closeDrawer", true);
                    this.filters = false
                }
            },
            permissions(permission){
                if(this.currentUser.id==1){
                    return true
                }else if(this.currentUser.permissions!=undefined){
                    if(this.currentUser.permissions.includes(permission)){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            rejection(id){
                this.statusId = id
                this.rejectionDialog = true
            },
            confirmStatus(id, status){
                this.statusId = id
                this.statusData = status
                this.sheet2 = true
            },
            status(){
                if(this.statusData=='Activa'){
                    axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + this.statusId, { is_active:1 }).then(response => {
                        this.getDataFromApi()
                        this.statusId = ''
                        this.statusData = ''
                        this.sheet2 = false
                    });
                }
                else if(this.statusData=='Finalizada'){
                    axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + this.statusId, { is_active:0 }).then(response => {
                        this.getDataFromApi()
                        this.statusId = ''
                        this.statusData = ''
                        this.sheet2 = false
                    });
                }
                else if(this.statusData=='Vencida'){
                    axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + this.statusId, { is_expired:1 }).then(response => {
                        this.getDataFromApi()
                        this.statusId = ''
                        this.statusData = ''
                        this.sheet2 = false
                    });
                }else{
                    /*var editedItem = this.quotationsList.filter(quotation=>quotation.id == this.statusId)[0]
                    editedItem.status = this.statusData
                    editedItem.rejection_id = this.rejectionId
                    editedItem.rejection_comment = this.rejectionData
                    editedItem.last_updated_by_user_id = this.currentUser.id*/
                    axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sale/cancel/" + this.statusId).then(response=>{
                        this.statusId = ''
                        this.statusData = ''
                        this.sheet2 = false
                        this.rejectionId = ''
                        this.rejectionData = ''
                        this.rejectionDialog = false
                        this.$store.dispatch('quotation/getSales')
                        this.$store.dispatch('quotation/getCancellations')
                    })
                }
            },
            filtersQuotation: function(params) {
                this.getDataFromApi()
            },
            closeDialogEditQuotation: function(params) {
                this.editDialog = false;
                this.getDataFromApi()
                this.$store.dispatch('quotation/getTotals')
            },
            closeCreateDialogQuotation: function(params) {
                this.createDialog = false;
                this.getDataFromApi()
                this.$store.dispatch('quotation/getTotals')
            },
            closeDialogEmailQuotation: function(params) {
                this.emailDialog = false;
            },
            closeCreateDialogExpense: function(params) {
                this.createExpenseDialog = false;
                this.getDataFromApi()
            },
            exportExcel: function (itemsPerPage) {
                /*let data = XLSX.utils.json_to_sheet(this.quotations.map(id=>{return{
                    'Folio': id.id,
                    'Departamento':id.booking,
                    'Influencer':id.influencer!=undefined?id.influencer.social_networks.instagram:'',
                    'Agencia':id.agency,
                    'Marca':id.brand,
                    'Servicio':id.service,
                    'Campaña':id.campaign,
                    'Fecha de Servicio':id.service_date,
                    'Mes':id.month,
                    'Subtotal':id.subtotal,
                    'IVA':id.iva,
                    'Total':id.total,
                    'Gastos':id.expense_amount,
                    '% Peach':id.percent_peach,
                    'Monto Peach':id.amount_peach,
                    '% Influencer':id.percent_influencer,
                    'Monto Influencer':id.influencer_amount,
                    'Fecha Pago Influencer':id.influencer_payment_date,
                    'Factura':id.invoice,
                    'Fecha Factura':id.invoice_date,
                    //'Fecha Promesa de Pago':id.payment_promise_date,
                    'Fecha de Pago':id.pay_day,
                    'Responsable':id.salesman,
                    'Fecha de Corte':id.deadline_date,
                    'Fecha de Creación':id.created_at,
                    'Factura Influencer':id.invoice_file!=null?'Si':'No',
                    'Factura Marca':id.peach_invoice_file!=null?'Si':'No',
                    'Contrato':id.pdfs!=undefined&&id.pdfs!=null&&id.pdfs.length>0?'Si':'No',
                    //'Activo/Finalizado':id.is_expired?'Finalizado':'Activo',
                    'Activo/Finalizado':id.is_active?'Activo':'Finalizado',
                }}))
                const workbook = XLSX.utils.book_new()
                const filename = 'Lista de Ventas'
                XLSX.utils.book_append_sheet(workbook, data, filename)
                XLSX.writeFile(workbook, `${filename}.xlsx`)*/

                var link = this.$store.state.currentUser.sale_filter

                if(localStorage.getItem('filtersSales')!=null){
                    link = link + JSON.parse(localStorage.getItem('filtersSales'))+'&'
                }
                if(this.prop_brand_id!=undefined){
                    link = link + 'filter[brand_id]=' + this.prop_brand_id + '&'
                }
                if(this.prop_agency_id!=undefined){
                    link = link + 'filter[agency_id]=' + this.prop_agency_id + '&'
                }
                if(this.influencer!=undefined){
                    link = link + 'filter[influencer_id]=' + this.influencer + '&'
                }
                if(this.selectedStatus!='all'){
                    link = link + 'filter[' + this.selectedStatus + ']=1&'
                }
                if(this.$route.params.sale_id!=undefined){
                    link = link + 'filter[id]=' + this.$route.params.sale_id + '&'
                }

                let config = {
                    responseType: 'blob',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("xn8(*&^%$#47b*g3f76gw3*&^fn734gf8q*&^&^&mwmeg934g8whmre7ngN&G#*!NG@*&#GF(!*#gm1n428fm1g3n84gmf*^$&(%$)e3298negmg"),
                        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }
                }

                axios.get('https://unopack.mx/api/v1/sale/export?' + link + 'itemsPerPage=' + itemsPerPage + '&status=vendido&export=true', config).then((response) => {
                   const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ventas.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                })
            },
            deleteQuotation(){
                axios.delete(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/"+this.deleteId).then(response => {
                    this.deleteId = ''
                    this.sheet = false
                    this.getDataFromApi()
                });
            },
            cancellItem(sale_id){
                var editedItem = {
                    id:sale_id,
                    sale_status: 'cancelado'
                }
                axios.patch(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/sales/" + sale_id, editedItem).then(response => {
                    this.getDataFromApi()
                });
            },
            sendEmail (id) {
                this.quotation = this.quotationsList.filter(quotation=>quotation.id == id)[0]
                this.emailDialog = true
            },
            cancel(){
                this.deleteId = ''
                this.sheet = false
                this.sheet2 = false
            },
            deleteItem (id) {
                this.deleteId = id
                this.sheet = true
            },
            haveAgency(agency){
                if(agency!=undefined){
                    return agency.id
                }else{
                    return ''
                }
            },
            editItem(item){
                console.log(item)
                this.quotation = [item].map(id=>{
                    return{
                        id:id.id,
                        agency_id:this.haveAgency(id.agency),
                        brand_id:this.haveAgency(id.brand),
                        user_id:this.haveAgency(id.agent),
                        pdfs:id.pdfs,
                        description:id.description,
                        sale_type:id.sale_type,
                        invoice:id.invoice,
                        influencer_id:this.haveAgency(id.influencer),
                        service_date:id.service_date,
                        service:id.service,
                        subtotal:id.subtotal,
                        total:id.total,
                        agency:id.agency,
                        brand:id.brand,
                        influencer:id.influencer,
                        influencer_amount:id.influencer_amount,
                        campaign:id.campaign,
                        invoice_date:id.invoice_date,
                        payment_promise_date:id.payment_promise_date,
                        deadline_date:id.deadline_date,
                        invoice_file:id.invoice_file,
                        peach_invoice_file:id.peach_invoice_file,
                        campaign_contact_id:id.campaign_contact!=undefined?id.campaign_contact.id:'',
                        finance_contact_id:id.finance_contact!=undefined?id.finance_contact.id:''
                    }
                })[0]
                this.editDialog = true
            },
        }
    }
</script>
<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
    
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
        padding: 0 8px!important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        font-size:13px!important;
    }
    .v-row-group__header .ma-0.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--small:nth-child(2){
        display:none!important;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
    .theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
        background: #f4f5fa;
    }
    .v-text-field--outlined fieldset {
        border-color: #e0e0e0;
    }
    .v-list-item__action {
        margin: 0px 20px 0px 0px!important;
    }
    .v-menu__content.theme--light.menuable__content__active{
        max-height: 85vh!important;
    }

    .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
        padding: 0 0px!important;
    }
    .btnText2{
        padding: 0px!important;
        height: auto!important;
        background: rgb(219, 219, 219)!important;
        box-shadow: none!important;
        /*text-transform: none;*/
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0;
        color: #1f1f1f!important;
        text-decoration: none!important;
    }


    .is_active{
        border-left: 3px solid #4385f3;
    }
    .is_inactive{
        border-left: 3px solid #33a952;
    }

    .is_activeStyle-1 {
        background-color: #fff2ca;
        border-left: 3px solid #4385f3;
    }
    .is_activeStyle-2 {
        background-color: #d9ebff;
        border-left: 3px solid #4385f3;
    }
    .is_activeStyle-3 {
        background-color: #0080002b;
        border-left: 3px solid #4385f3;
    }
    .is_activeStyle-4 {
        background-color: #ff75002b;
        border-left: 3px solid #4385f3;
    }

    .is_inactiveStyle-1 {
        background-color: #fff2ca;
        border-left: 3px solid #33a952;
    }
    .is_inactiveStyle-2 {
        background-color: #d9ebff;
        border-left: 3px solid #33a952;
    }
    .is_inactiveStyle-3 {
        background-color: #0080002b;
        border-left: 3px solid #33a952;
    }
    .is_inactiveStyle-4 {
        background-color: #ff75002b;
        border-left: 3px solid #33a952;
    }


    table{
        border-collapse: collapse;
    }
</style>


